<template>
    <div> 
        <IconTopTable />
        
        <div class="content-table-results mt-2">
            <table class="table table-fixed table-stripes">
                <ColumnColGroup page="result" :column="data.column" :default_column="data.default_column" />
                <ColumnThead page="result" :column="data.column" :default_column="data.default_column" />
                <tbody> 
                    <template v-if="data.data.data && data.data.data.length > 0">
                        <tr v-for="(item, key) in data.data.data" :key="key" >                
                            
                            <td>
                                <label class="table-check">
                                    <input type="checkbox"  v-model="select_export" :value="item.drug_id">
                                    <span class="checkbox-table"></span>
                                </label>
                            </td>
                            <td>
                                <router-link :to="'/detail/'+item.drug_id+'/'+item.agency_model.toLowerCase()" target="_blank" rel="noopener">
                                    <div class="icon-fiche"></div>
                                </router-link>
                                <div v-if="isAdmin()">
                                    <a :href="modifyPage('fdas', item.drug_id)" target="_blank" rel="noopener"> 
                                        <em class="icon-edit"></em>
                                    </a>
                                </div>
                            </td>
                            <template v-for="(column) in data.column" :key="column.code" >


                                <!-- <td v-if="column.Status && column.code == 'fda_001'" :code="column.code">                                                           
                                    <span v-html="icon_link(item.drug_id,'fda')"></span>
                                </td> -->
                                <td v-if="column.Status && column.code == 'fda_002'" :code="column.code">
                                    <span v-html="ema_id(item.links)"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'fda_003'" :code="column.code">
                                    {{item.drug_id}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'fda_023'" :code="column.code">
                                    <span v-html="check_empty(overview_regulatory_status(item.current_ma_status))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'fda_004'" :code="column.code">                                
                                    <span v-html="column_status(item)"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'fda_005'" :code="column.code">
                                    {{check_empty(item.category)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'fda_006'" :code="column.code">
                                    {{check_empty(item.dci_new)}}
                                </td>
                                <!-- <td v-else-if="column.Status && column.code == 'fda_007'" :code="column.code">
                                    <template >{{DDMMMYY(item.decision_date_num)}}</template> 
                                </td> -->
                                 <td v-else-if="column.Status && column.code == 'fda_025'" :code="column.code">
                                    {{check_empty(item.decision)}}
                                </td>
                                 <td v-else-if="column.Status && column.code == 'fda_026'" :code="column.code">
                                    {{check_empty(DDMMMYY(item.decision_date_num))}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'fda_008'" :code="column.code">
                                    {{check_empty(item.name)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'fda_009'" :code="column.code">
                                    {{check_empty(item.dci)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'fda_010'" :code="column.code">
                                    <span v-html="check_empty(array(item.atc))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'fda_011'" :code="column.code">
                                    {{check_empty(item.firm)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'fda_012'" :code="column.code">                                
                                    <span v-html="check_empty(array(item['indexation_'+$i18n.locale]))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'fda_013'" :code="column.code">
                                    <div v-html="check_empty(fda_review_classification(item.fda))"></div>
                                </td>
                                <td v-else-if="column.Status && column.code == 'fda_014'" :code="column.code">
                                    <div v-if="$i18n.locale == 'fr'" v-html="check_empty(array(item.type_of_ma_fr))"></div>
                                    <div v-else v-html="check_empty(array(item.type_of_ma_en))"></div>
                                </td>
                                <td v-else-if="column.Status && column.code == 'fda_015'" :code="column.code">
                                    <span v-html="check_empty(treatment_line(item.treatment_line))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'fda_016'" :code="column.code">
                                    {{check_empty(item.specificity)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'fda_017'" :code="column.code">
                                    {{check_empty(item.administration)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'fda_018'" :code="column.code">
                                    <Readmore :longText="item.fda.comments" />
                                </td>
                                <td v-else-if="column.Status && column.code == 'fda_019'" :code="column.code">                                
                                    <Readmore :longText="item.indication_en"/>
                                </td>                            
                                <td v-else-if="column.Status && column.code == 'fda_020'" :code="column.code">
                                    <span v-if="$i18n.locale === 'fr'" v-html="check_empty(array(item.classification_fr))"></span>
                                    <span v-else v-html="check_empty(array(item.classification_en))"></span>
                                </td>                            
                                <td v-else-if="column.Status && column.code == 'fda_021'" :code="column.code">
                                    {{check_empty_with_locale(item.mk_orphelin)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'fda_022'" :code="column.code">
                                    <span v-html="nct_number(item.nct_numbers)" @click="drug_id = item.drug_id"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'fda_024'" :code="column.code">
                                    <span v-html="check_empty(DDMMMYY(item.fda.pdufa_date))"></span>
                                </td>
                            </template>

                        </tr>

                    </template>

                    <template v-else><tr><td colspan="99">{{$t('No Data')}}</td></tr></template>

                </tbody>

            </table>
        </div>      
        <ModalEssaisClinique :id="drug_id" agency="Fda" />
    </div>
</template>
 

<script>

import IconTopTable from '@/components/result/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue';
import ModalEssaisClinique from '@/components/elements/modal_essais_clinique.vue';
import Readmore from '@/components/elements/readmore.vue';
import { isAdmin, overview_regulatory_status, column_status, modifyPage, fda_review_classification, 
        ema_id, icon_link, DDMMMYY, check_empty, nct_number, treatment_line, datediff, array, check_empty_with_locale } from '@/utils'
export default {
    name: "Fda",
    components: {
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        ModalEssaisClinique,
        Readmore
    },
    data() {
        return {
            drug_id : 0 //for essais clinique popup
        }
    },
    computed: {                
        data(){
            return this.$store.getters['result/data'];
        }, 
        select_export: {
            set(val){ this.$store.dispatch("result/select_export", val) },
            get(){ 
                if(this.$store.getters['result/select_export_checkAll']){
                    return true
                }else{
                    return this.$store.getters['result/select_export'] ? this.$store.getters['result/select_export'].listID : null 
                }
            }
        }
    },
    methods: {    
        overview_regulatory_status, 
        isAdmin,
        column_status,
        fda_review_classification,  
        ema_id,
        icon_link,
        DDMMMYY,
        check_empty,
        nct_number,
        treatment_line,
        datediff,
        array,
        modifyPage,
        check_empty_with_locale
    },
}
</script>

<style scoped>
/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 70vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>